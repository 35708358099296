<template>
  <div>
    <v-card class="mb-5">
      <v-card-title> Manage Organisation "{{ groupName }}"</v-card-title>
    </v-card>
    <AdminListUsers :group-uuid="groupUuid" />
  </div>
</template>

<script>
import AdminListUsers from '@/views/admin/AdminListUsers.vue'
import Groups from '@/classes/Groups'

export default {
  components: {
    AdminListUsers,
  },
  props: {
    groupUuid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      groupName: '???',
    }
  },
  mounted() {
    Groups.onLoaded(() => {
      this.groupName = Groups.getNameById(this.groupUuid)
    })
  },
  methods: {},
}
</script>
